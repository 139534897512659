import moment from 'moment';
import { API_DATE_FORMAT } from '../constants/api';

export const getRandomListFromArr = (arr, count: number) => {
  arr.sort(() => Math.random() - 0.5);
  return arr.length > count ? arr.splice(0, count) : arr;
};

export const randomInteger = (min: number, max: number): number => {
  return Math.round(min - 0.5 + Math.random() * (max - min + 1));
};

export const getFBCategoryName = (category: string): string => {
  switch (category) {
    case 'B':
    case 'A,A1':
      return 'BAA1';
    case 'F,G':
      return 'FG';
    default:
      return category;
  }
};

export const getTheoryImage = (id: string): string => `/content/theory/${id}.jpg`;

const separateLinePattern = /\n{1}/;

export const splitTextByLines = (text: string): string[] => text.split(separateLinePattern);

export const getTheoryContentImgAnchorIndex = (text: string): string | null => {
  if (text.includes('[IMAGE') && text.includes(']')) {
    return text
      .replace('[IMAGE', '')
      .replace(']', '')
      .trim();
  }
  return null;
};
const separator = '<|+|>';
const imgPlaceholderPattern = /\[IMAGE[0-9]+]/g;

export const splitTextContentWithImgToChunks = (text: string): string[] =>
  text
    .replace(imgPlaceholderPattern, `${separator}$&${separator}`)
    .split(separator)
    .map(item => item.trim())
    .filter(Boolean);

const sharedUrl: string = 'https://app.theorie24.ch/';

export const getFBShareLink = (lang: string): string =>
  `https://www.facebook.com/sharer/sharer.php?u=${sharedUrl}${lang}/`;
export const getLiShareLink = (lang: string): string =>
  `https://www.linkedin.com/sharing/share-offsite/?url=${sharedUrl}${lang}/`;

export const formatTextValue = (value: string, emptyPlaceholder: string = '-'): string => {
  return value.trim() ? value : emptyPlaceholder;
};

export const scrollToAnchor = (id: string, args?: ScrollIntoViewOptions): void => {
  const newId = id.startsWith('#') ? id.slice(1) : id;
  const { block = 'start', behavior = 'smooth' } = args || {};
  const element: HTMLElement | null = document.getElementById(newId);

  if (element) {
    element.scrollIntoView({ block, behavior });
  }
};

export const isPlanExpired = (expirePlaneDateTime?: string | null): boolean => {
  if (!expirePlaneDateTime) {
    return false;
  }

  const expiredMoment = moment(expirePlaneDateTime, API_DATE_FORMAT);
  if (!expiredMoment.isValid()) {
    return false;
  }

  const now = moment();
  return now.isAfter(expiredMoment);
};

export const getPlanDateCountLabel = (expirePlaneDateTime?: string, expiredLabel: string = '0'): string => {
  if (expirePlaneDateTime) {
    if (isPlanExpired(expirePlaneDateTime)) {
      return expiredLabel;
    }

    return String(moment(expirePlaneDateTime, API_DATE_FORMAT).diff(moment(), 'days'));
  }

  return expiredLabel;
};
