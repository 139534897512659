import { handleActions } from 'redux-actions';
import { INavigationMenuState } from './navigationMenu.types';
import { NAVIGATION_IS_OPEN_TOGGLE } from '../actions/navigationMenu.action';

const defaultState: INavigationMenuState = {
  navigationIsOpen: false
};

const reduces = handleActions(
  {
    [NAVIGATION_IS_OPEN_TOGGLE]: (state, action) => ({
      ...state,
      navigationIsOpen: action.payload ? !state.navigationIsOpen : action.payload
    })
  },
  defaultState
);

export default reduces;
