import React, { useRef } from 'react';
import ReactGA from 'react-ga';
import { useTranslation } from 'react-i18next';
import { ICongratulationProps } from './Congratulation.types';
import failure1 from '../../img/result/failure_1.gif';
import failure2 from '../../img/result/failure_2.gif';
import failure3 from '../../img/result/failure_3.gif';
import failure4 from '../../img/result/failure_4.gif';
import success1 from '../../img/result/success_1.gif';
import success2 from '../../img/result/success_2.gif';
import success3 from '../../img/result/success_3.gif';
import success4 from '../../img/result/success_4.gif';
import { PageTitle } from '../../ui-components/PageTitle/PageTitle';
import Image from '../../ui-components/Image/Image';
import { randomInteger } from '../../utils/utils';
import styles from './Congratulation.module.css';
import { P3 } from '../../ui-components/P3/P3';

const getImageSrc = (isSuccess: boolean): string => {
  const randomNumber = randomInteger(1, 4);
  switch (randomNumber) {
    case 1:
      return isSuccess ? success1 : failure1;
    case 2:
      return isSuccess ? success2 : failure2;
    case 3:
      return isSuccess ? success3 : failure3;
    case 4:
      return isSuccess ? success4 : failure4;

    default:
      return isSuccess ? success1 : failure1;
  }
};

const Congratulation: React.FC<ICongratulationProps> = ({ isSuccess, isLogged }: ICongratulationProps) => {
  const { t } = useTranslation();
  const randomGifRef = useRef(getImageSrc(isSuccess));

  if (process.env.REACT_APP_GOOGLE_GA_KEY) {
    ReactGA.event({
      category: 'exam',
      action: isSuccess ? 'passed' : 'failed',
      value: 1
    });
  }

  return (
    <div className={styles.resultPageItem}>
      <PageTitle>{t(`examResult.result.${isSuccess ? 'correct' : 'incorrect'}.title`)}</PageTitle>
      <Image src={randomGifRef.current} />
      <P3>{t(`examResult.result.${isSuccess ? 'correct' : 'incorrect'}.${isLogged ? 'textLogged' : 'text'}`)}</P3>
      <ReactGA.OutboundLink
        eventLabel="routinieroutbound"
        to="https://senior.routinier24.ch?utm_source=ACS70&utm_medium=button&utm_campaign=examfinished"
      >
        <button type="button" className="btn btn-secondary">
          {t('examResult.btn.deals')}
        </button>
      </ReactGA.OutboundLink>
    </div>
  );
};

export default Congratulation;
