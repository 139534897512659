import {
  IGetTheoryByKey,
  ChaptersAttributesSchema,
  ITheory,
  TChapters,
  TPages,
  PagesAttributesSchema,
  TRegisters,
  RegistersAttributesSchema
} from './theory.schema';

const chapterJson = {
  de: [],
  fr: [],
  it: [],
  en: []
};

const pageJson = {
  de: [],
  fr: [],
  it: [],
  en: []
};

const registerJson = {
  de: [],
  fr: [],
  it: [],
  en: []
};

export const getTheory = async ({ language }: IGetTheoryByKey) => {
  const theory: ITheory = {
    chapters: [],
    pages: [],
    registers: []
  };

  await Promise.all([
    Promise.all(
      chapterJson[language].map(async item => {
        const data = item as TChapters;
        if (await ChaptersAttributesSchema.isValid(data)) {
          theory.chapters.push(data);
        }
      })
    ),
    Promise.all(
      pageJson[language].map(async item => {
        const data = item as TPages;
        if (await PagesAttributesSchema.isValid(data)) {
          theory.pages.push(data);
        }
      })
    ),
    Promise.all(
      registerJson[language].map(async item => {
        const data = item as TRegisters;
        if (await RegistersAttributesSchema.isValid(data)) {
          theory.registers.push(data);
        }
      })
    )
  ]);

  return { theory, language };
};
