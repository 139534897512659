import { createAction } from 'redux-actions';

export const CLEAR_USER = createAction('CLEAR_USER');

export const SET_LANGUAGE_TOGGLE_STATE = createAction('SET_LANGUAGE_TOGGLE_STATE');

export const SET_SELECTED_QUESTION_LANGUAGE = createAction('SET_SELECTED_QUESTION_LANGUAGE');

export const SET_USER_SELECTED_TOPIC = createAction('SET_USER_SELECTED_TOPIC');
export const SET_USER_LANGUAGE_SUCCESS = createAction('SET_USER_LANGUAGE_SUCCESS');
export const SET_USER_SELECTED_CATEGORY = createAction('SET_USER_SELECTED_CATEGORY');
export const SET_USER_SELECTED_QUESTION = createAction('SET_USER_SELECTED_QUESTION');
export const SET_USER_QUESTION_LANGUAGE_SUCCESS = createAction('SET_USER_QUESTION_LANGUAGE_SUCCESS');

export const GET_USER_EXAM_RESULTS_REQUEST = createAction('GET_USER_EXAM_RESULTS_REQUEST');
export const GET_USER_EXAM_RESULTS_SUCCESS = createAction('GET_USER_EXAM_RESULTS_SUCCESS');
export const GET_USER_EXAM_RESULTS_FAILURE = createAction('GET_USER_EXAM_RESULTS_FAILURE');

export const SET_USER_STATISTICS = createAction('SET_USER_STATISTICS');
