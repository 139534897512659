import ExamCardPage from '../pages/ExamCardPage/ExamCardPage';
import ExamResultPage from '../pages/ExamResultPage/ExamResultPage';
import ExamPage from '../pages/ExamPage/ExamPage';
import TheoryPage from '../pages/TheoryPage/TheoryPage';
import DrivingskillsPage from '../pages/DrivingskillsPage/DrivingskillsPage';
import EcodrivePage from '../pages/EcodrivePage/EcodrivePage';
import SeniorPage from '../pages/SeniorPage/SeniorPage';
import ImprintPage from '../pages/ImprintPage/ImprintPage';
import TermPage from '../pages/TermPage/TermPage';
import AsaPage from '../pages/AsaPage/AsaPage';
import QuestionCardPage from '../pages/QuestionCardPage/QuestionCardPage';
import QuestionPage from '../pages/QuestionPage/QuestionPage';
import ContactPage from '../pages/ContactPage/ContactPage';
import FaqPage from '../pages/FaqPage/FaqPage';
import AnleitungPage from '../pages/AnleitungPage/AnleitungPage';
import { RoutesTypes } from './routes.types';
import NotFoundContainer from '../containers/NotFoundСontainer/NotFoundContainer';
import AboutPageContainer from '../containers/AboutPageContainer/AboutPageContainer';
import TheoryBookByTopicsPage from '../pages/TheoryBookByTopicsPage/TheoryBookByTopicsPage';
import TheoryGlossaryPage from '../pages/TheoryGlossaryPage/TheoryGlossaryPage';
import CockpitNotLoggedPage from '../pages/CockpitNotLoggedPage/CockpitNotLoggedPage';

export const routes: RoutesTypes[] = [
  {
    path: '/',
    exact: true,
    component: ExamPage
  },
  {
    path: '/exam/:examRoute/:id?',
    renderAfterLoad: true,
    component: ExamCardPage
  },
  {
    path: '/about',
    component: AboutPageContainer
  },
  {
    path: '/result/:examRoute',
    renderAfterLoad: true,
    component: ExamResultPage
  },
  {
    path: '/exam',
    renderAfterLoad: true,
    component: ExamPage
  },
  {
    path: '/theory/topics/:page',
    demoPathName: '/',
    renderAfterLoad: true,
    component: TheoryBookByTopicsPage
  },
  {
    path: '/theory/glossary/:page',
    renderAfterLoad: true,
    demoPathName: '/',
    component: TheoryGlossaryPage
  },
  {
    path: '/theory',
    component: TheoryPage
  },
  {
    path: '/drivingskills',
    component: DrivingskillsPage
  },
  {
    path: '/ecodrive',
    component: EcodrivePage
  },
  {
    path: '/senior',
    component: SeniorPage
  },
  {
    path: '/imprint',
    component: ImprintPage
  },
  {
    path: '/term',
    component: TermPage
  },
  {
    path: '/asa',
    component: AsaPage
  },
  {
    path: '/questions/:category/:topic/:id?',
    renderAfterLoad: true,
    component: QuestionCardPage
  },
  {
    path: '/questions',
    renderAfterLoad: true,
    component: QuestionPage
  },
  {
    path: '/contacts',
    component: ContactPage
  },
  {
    path: '/mycockpitdemo',
    renderAfterLoad: true,
    component: CockpitNotLoggedPage
  },
  {
    path: '/faq',
    component: FaqPage
  },
  {
    path: '/anleitung',
    component: AnleitungPage
  },
  {
    path: '*',
    component: NotFoundContainer
  }
];
