import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import { PageTitle } from '../ui-components/PageTitle/PageTitle';
import { RulesGroupItem } from '../ui-components/RulesGroupItem/RulesGroupItem';
import { IRootState } from '../reducer';
import { LinkWrapper } from '../ui-components/LinkWrapper/LinkWrapper';
import styles from './CockpitNotLoggedTopContainer.module.css';

const CockpitNotLoggedTopContainer: React.FC = () => {
  const { t } = useTranslation();

  const selectedCategory = useSelector(({ user }: IRootState) => user.profile.currentCategory);

  return (
    <RulesGroupItem>
      <PageTitle>{t('CockpitNotLoggedTopContainer.title')}</PageTitle>
      <div className={styles.btnGroup}>
        <Button as={LinkWrapper} to="/offers" variant="primary">
          {t('CockpitNotLoggedTopContainer.dealsButton')}
        </Button>
        <Button as={LinkWrapper} to={`/questions/${selectedCategory}/demo`} variant="outline-primary">
          {t('CockpitNotLoggedTopContainer.test')}
        </Button>
        <Button as={LinkWrapper} to="/exam" variant="outline-primary">
          {t('CockpitNotLoggedTopContainer.exam')}
        </Button>
      </div>
    </RulesGroupItem>
  );
};

export default CockpitNotLoggedTopContainer;
