import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useReactRouter from 'use-react-router';
import Counter from '../../ui-components/Counter/Counter';
import QuestionView from '../../components/QuestionView/QuestionView';
import QuestionBar from '../../components/QuestionBar/QuestionBar';
import { IRootState } from '../../reducer';
import { CategoryType, IQuestion } from '../../reducer/question.types';
import { RequestStatusType } from '../../reducer/fetch.types';
import { IExamCardContainerProps, IUnblock } from './ExamCardContainer.types';
import {
  SET_LANGUAGE_TOGGLE_STATE,
  SET_SELECTED_QUESTION_LANGUAGE,
  SET_USER_QUESTION_LANGUAGE_SUCCESS,
  SET_USER_SELECTED_QUESTION
} from '../../actions/user.action';
import {
  ADD_EXAM_ANSWER_REQUEST,
  CHANGE_EXAM_ANSWER,
  FINISH_EXAM_REQUEST,
  RECOVERY_EXAM_REQUEST
} from '../../actions/exam.action';
import { Loader } from '../../ui-components/Loader/Loader';
import { IUserAnswer } from '../../reducer/user.types';
import { IUserExamAnswer } from '../../reducer/exam.types';
import Timer from '../../ui-components/Timer/Timer';
import { FAILURE, PENDING } from '../../constants/store.constants';
import { selectLanguage } from '../../reducer/user.selectors';
import { LinkWrapper } from '../../ui-components/LinkWrapper/LinkWrapper';
import FinishExamModal from '../../components/FinishExamModal/FinishExamModal';
import LeaveExamModal from '../../components/LeaveExamModal/LeaveExamModal';
import { usePrevious } from '../../hooks/usePrevious';
import { LoginState } from '../../reducer/login.types';
import { selectLogin } from '../../reducer/login.selectors';
import styles from './ExamCardContainer.module.css';

export const ExamCardContainer: React.FC<IExamCardContainerProps> = ({ routeParams }: IExamCardContainerProps) => {
  const { isLogged }: LoginState = useSelector(selectLogin);
  const [showLeaveExamModal, setShowLeaveExamModal] = useState(false);
  const [nextPath, setNextPath] = useState('');
  const [unblock, setUnblock] = useState<IUnblock>({});
  const [showFinishExamModal, setShowFinishExamModal] = useState(false);
  const [timeFinish, setTimeFinish] = useState(false);
  const isFinished: boolean = useSelector(({ exam }: IRootState) => exam.isFinished);
  const questionList: IQuestion[] = useSelector(({ exam }: IRootState) => exam.examQuestionList);
  const requestStatus: RequestStatusType = useSelector(({ exam }: IRootState) => exam.examQuestionListRequest.status);
  const recoveryStatus: RequestStatusType = useSelector(({ exam }: IRootState) => exam.recoveryExamRequest.status);
  const questionLanguage: string = useSelector(({ user: { profile } }: IRootState) => profile.questionLanguage);
  const selectedQuestionLang: string | undefined = useSelector(
    ({ user: { profile } }: IRootState) => profile.selectedQuestionLang
  );
  const toggleLngState: boolean | undefined = useSelector(
    ({ user: { profile } }: IRootState) => profile.toggleLngState
  );
  const category: CategoryType = useSelector(({ user }: IRootState) => user.profile.currentCategory);
  const { examRoute, id = 1 } = routeParams;
  const selectedQuestionId: number = id && +id > 0 && +id <= questionList.length ? +id - 1 : 0;
  const question: IQuestion = questionList[selectedQuestionId];
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { history, location } = useReactRouter();
  const changeLanguage = useCallback(
    (language: string): void => {
      if (language !== questionLanguage) {
        dispatch(SET_USER_QUESTION_LANGUAGE_SUCCESS({ language }));
      }
    },
    [dispatch, questionLanguage]
  );
  const examId: string = useSelector(({ exam: { currentExamId } }: IRootState) => currentExamId);
  const examStartTime: number = useSelector(({ exam }: IRootState) => exam.examStartTime);
  const duration: number = useSelector(({ exam }: IRootState) => exam.duration);
  const examAnswer: IUserExamAnswer = useSelector(({ exam: { examAnswerList } }: IRootState) => {
    return examAnswerList[examId] || {};
  });
  const userAnswer: IUserAnswer | null = question && examAnswer && examAnswer ? examAnswer[question.id] : null;
  const isFavorite = !!(userAnswer && userAnswer.favorite);
  const isCorrect: boolean | undefined = userAnswer ? userAnswer.correct : undefined;
  const userLanguage = useSelector(selectLanguage);
  const prevLanguage = usePrevious(userLanguage);
  const isFinishedExam = timeFinish || isFinished;

  const setToggleState = useCallback(toggleState => dispatch(SET_LANGUAGE_TOGGLE_STATE({ toggleState })), [dispatch]);

  const unblockHistory = useCallback(() => {
    unblock.func && unblock.func();
  }, [unblock]);

  const handleToggleChangeLng = useCallback(
    (newLng: string) => {
      setToggleState(false);
      dispatch(SET_SELECTED_QUESTION_LANGUAGE(newLng));
      changeLanguage(newLng);
    },
    [dispatch, changeLanguage, setToggleState]
  );

  const handleLngToggleClick = useCallback(() => {
    changeLanguage(!toggleLngState ? userLanguage : selectedQuestionLang);
    setToggleState(!toggleLngState);
  }, [toggleLngState, setToggleState, changeLanguage, selectedQuestionLang, userLanguage]);

  const handleExamFinish = useCallback(() => {
    unblockHistory();
    dispatch(FINISH_EXAM_REQUEST({ examId }));
  }, [dispatch, examId, unblockHistory]);

  const redirAndhandleExamFinish = () => {
    handleExamFinish();
    history.push(`/${userLanguage}/result/${examId}`);
  };

  const handleShowFinishExamModal = useCallback(() => {
    if (!isFinished) {
      setShowFinishExamModal(true);
    }
  }, [setShowFinishExamModal, isFinished]);

  const handleHideFinishExamModal = useCallback(() => {
    setShowFinishExamModal(false);
  }, [setShowFinishExamModal]);

  const handleTimeFinish = useCallback(() => {
    if (!isFinished) {
      setTimeFinish(true);
      handleExamFinish();
    }
  }, [handleExamFinish, isFinished]);

  useEffect(() => {
    if (timeFinish) {
      handleShowFinishExamModal();
    }
  }, [timeFinish, handleShowFinishExamModal]);

  const handleAnswerChange = useCallback(
    (checked: boolean, answerId: number) => {
      dispatch(CHANGE_EXAM_ANSWER({ answerId, question, category, userAnswer, examId }));
    },
    [dispatch, userAnswer, question, category, examId]
  );

  const handleToFavorite = useCallback(
    (questionId: number) => {
      dispatch(
        ADD_EXAM_ANSWER_REQUEST({
          questionId,
          examId,
          answer: {
            selected: examAnswer[questionId].selected,
            correct: !!examAnswer[questionId].correct,
            favorite: !examAnswer[questionId].favorite
          }
        })
      );
    },
    [dispatch, examId, examAnswer]
  );

  const handleToResult = useCallback(() => {
    history.push(`/${userLanguage}/result/${examId}`);
  }, [history, examId, userLanguage]);

  const goToNextRoute = useCallback(
    (path: string) => {
      if (path) {
        history.push(path);
      }
    },
    [history]
  );

  const handleGoToNextRoute = useCallback(() => {
    if (nextPath.length) {
      dispatch(FINISH_EXAM_REQUEST({ examId }));
      unblockHistory();
      goToNextRoute(nextPath);
    }
  }, [goToNextRoute, nextPath, examId, dispatch, unblockHistory]);

  const handleQuestionChange = useCallback(
    (link: string) => {
      unblockHistory();
      history.push(link);
    },
    [history, unblockHistory]
  );

  const handleHideLeaveExamModal = useCallback(() => {
    setShowLeaveExamModal(false);
    unblockHistory();
  }, [setShowLeaveExamModal, unblockHistory]);

  useEffect(() => {
    dispatch(SET_USER_SELECTED_QUESTION(selectedQuestionId));
  }, [dispatch, selectedQuestionId]);

  useEffect(() => {
    if (examRoute.length && examRoute !== examId && recoveryStatus !== PENDING) {
      dispatch(RECOVERY_EXAM_REQUEST({ examId: examRoute }));
    }
  }, [dispatch, examRoute, examId, recoveryStatus]);

  useEffect(() => {
    if (recoveryStatus === FAILURE) {
      unblockHistory();
      history.push(`/${userLanguage}/exam`);
    }
  }, [recoveryStatus, history, userLanguage, unblockHistory]);

  useEffect(() => {
    let newUnblockHistory = () => {};
    if (!isFinished) {
      newUnblockHistory = history.block(targetLocation => {
        if (targetLocation.pathname.includes(`exam/${examRoute}`)) {
          newUnblockHistory();
          goToNextRoute(targetLocation.pathname);
        } else {
          setShowLeaveExamModal(true);
          setNextPath(targetLocation.pathname);
        }
        return false;
      });
      setUnblock({ func: newUnblockHistory });
    }
    return () => newUnblockHistory();
  }, [history, examRoute, userLanguage, goToNextRoute, showLeaveExamModal, recoveryStatus, isFinished, location]);

  useEffect(() => {
    if (typeof toggleLngState !== 'boolean' && prevLanguage && prevLanguage !== userLanguage) {
      setToggleState(!!toggleLngState);
    }
  }, [toggleLngState, dispatch, prevLanguage, userLanguage, setToggleState]);

  return (
    <div className={styles.questionPage}>
      <Loader show={requestStatus === PENDING || recoveryStatus === PENDING} />
      <LeaveExamModal show={showLeaveExamModal} onHide={handleHideLeaveExamModal} goToNextRoute={handleGoToNextRoute} />
      <FinishExamModal show={showFinishExamModal} onHide={handleHideFinishExamModal} onYes={handleToResult} />
      <Container>
        <Row>
          <Col>
            <div className={styles.questionBar}>
              <div className={styles.doubleCounter}>
                {duration && examStartTime && !isFinishedExam && (
                  <Timer
                    duration={isFinished ? 0 : duration * 60 * 1000}
                    startTime={examStartTime}
                    onFinish={handleTimeFinish}
                  />
                )}
                {question && <Counter current={selectedQuestionId + 1} maxSize={questionList.length} />}
              </div>

              <div>
                {question && (
                  <QuestionBar
                    isLogged={isLogged}
                    languageToggleState={toggleLngState}
                    selectedLang={toggleLngState && selectedQuestionLang ? selectedQuestionLang : userLanguage}
                    onLngToggleClick={handleLngToggleClick}
                    onToggleChangeLng={handleToggleChangeLng}
                    onQuestionChange={handleQuestionChange}
                    finishedExam={isFinishedExam}
                    userLanguage={userLanguage}
                    isFavorite={isFavorite}
                    isExam
                    defaultRoute={`/${userLanguage}/exam/${examId}`}
                    questionList={questionList}
                    addToFavorite={handleToFavorite}
                    questionId={+id}
                    onLngChange={changeLanguage}
                    lng={questionLanguage}
                    questionLngList={Object.keys(question.langs)}
                  />
                )}
              </div>
            </div>
          </Col>
        </Row>

        {question && (
          <QuestionView
            timeFinish={timeFinish || isFinished}
            isExam
            isCorrect={isCorrect}
            onQuestionChange={handleQuestionChange}
            onCheckedAnswerChange={handleAnswerChange}
            selectedAnswers={userAnswer ? userAnswer.selected : []}
            lng={questionLanguage}
            question={question}
            questionId={selectedQuestionId + 1}
            defaultRoute={`/${userLanguage}/exam/${examId}`}
            questionCount={questionList.length}
            handleExit={redirAndhandleExamFinish}
            isFinishedExam={isFinishedExam}
          />
        )}
        <div className={styles.questionLinkBottom}>
          <LinkWrapper
            className="btn-outline-secondary btnLabel btn"
            to={`/result/${examId}`}
            onClick={handleExamFinish}
          >
            {t('examCard.exit')}
          </LinkWrapper>
        </div>
      </Container>
    </div>
  );
};
