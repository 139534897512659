import alasql from 'alasql';

export const getAnswerList = async (userId: string, category: string) => {
  let answerList = {};

  const res = await alasql('SELECT qNr, answer, qTopic FROM answer WHERE qCat = ? AND uid = ?', [category, userId]);

  res.forEach(a => {
    answerList = {
      ...answerList,
      [a.qTopic]: answerList[a.qTopic]
        ? {
            ...answerList[a.qTopic],
            [a.qNr]: a.answer
          }
        : {
            [a.qNr]: a.answer
          }
    };
  });

  return answerList;
};

export const clearAnswerByTopic = async (userId: string, category: string, topic: string) => {
  if (topic === 'all' || topic === 'demo') {
    await alasql('DELETE FROM answer WHERE qCat = ? AND uid = ?', [category, userId]);
  } else {
    await alasql('DELETE FROM answer WHERE qTopic = ? AND qCat = ? AND uid = ?', [
      parseInt(topic, 10),
      category,
      userId
    ]);
  }
};

export const saveAnswerByTopic = async fbRequestParam => {
  const { userId, categoryForQuery, action } = fbRequestParam;

  const res = await alasql('SELECT * FROM answer WHERE qNr = ? AND qCat = ? AND uid = ?', [
    action.payload.questionId,
    categoryForQuery,
    userId
  ]);

  if (res.length) {
    await alasql('UPDATE answer SET answer = ? WHERE id = ?', [action.payload.answer, res[0].id]);
  } else {
    await alasql('INSERT INTO answer VALUES ?', [
      {
        qNr: action.payload.questionId,
        qCat: categoryForQuery,
        qTopic: action.payload.qTopic,
        uid: userId,
        answer: action.payload.answer
      }
    ]);
  }
};
