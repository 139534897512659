import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import useReactRouter from 'use-react-router';
import { IRootState } from '../reducer';
import Header from '../components/Header/Header';

const addBodyClass = className => document.body.classList.add(className);
const removeBodyClass = className => document.body.classList.remove(className);

const HeaderContainer: React.FC = () => {
  const navigationIsOpen = useSelector(({ navigationMenu }: IRootState) => navigationMenu.navigationIsOpen);
  const routeProps: RouteComponentProps = useReactRouter();
  const isLogged = useSelector(({ login }: IRootState): boolean => login.isLogged);

  useEffect(() => {
    if (navigationIsOpen) {
      addBodyClass('navigation-open');
    } else {
      removeBodyClass('navigation-open');
    }
  }, [navigationIsOpen]);
  return <Header isLogged={isLogged} routeProps={routeProps} />;
};

export default HeaderContainer;
