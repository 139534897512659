import alasql from 'alasql';

export const getExamById = async (userId: string, examId: string) => {
  const exams = await alasql('SELECT * FROM exam WHERE examId = ?', [examId]);

  return exams[0] ? exams[0] : null;
};

export const fbrecoveryExamAnswers = async (userId: string, examId: string) => {
  const examAnswerList = {};

  const answers = await alasql('SELECT * FROM examanswer WHERE examId = ?', [examId]);
  examAnswerList[examId] = {};
  answers.forEach(answer => {
    examAnswerList[examId][answer.qNr] = answer;
  });
  return examAnswerList;
};

export const updateAnswerPoint = async (userId, payload) => {
  await alasql('UPDATE exam SET trueQuestionCount = ?, truePointCount = ? WHERE examId = ?', [
    payload.trueQuestionCount,
    payload.truePointCount,
    payload.examId
  ]);
};

export const createExamData = async (userId, examId, data) => {
  const newData = { ...data, examId };
  const res = alasql('SELECT * FROM exam WHERE examId = ?', [examId]);
  if (res.length) {
    await alasql('UPDATE exam SET ? WHERE uid = ?', [data, res[0].uid]);
  } else {
    await alasql('SELECT * INTO exam FROM ?', [[newData]]);
  }
};

export const addExamAnswer = async (userId, examId, questionId, answer) => {
  const res = await alasql('SELECT * FROM examanswer WHERE examId = ? AND qNr = ?', [examId, questionId.toString()]);
  if (res.length) {
    const columns: string[] = [];
    const values: string[] = [];
    const columnKeys = Object.keys(answer);

    columnKeys.forEach(column => {
      columns.push(`${column.toString()} = ?`);
      values.push(answer[column]);
    });

    values.push(res[0].uid);
    await alasql(`UPDATE examanswer SET ${columns.join(', ')} WHERE uid = ?`, values);
  } else {
    await alasql('SELECT * INTO examanswer FROM ?', [
      [
        {
          ...answer,
          qNr: questionId.toString(),
          examId: examId.toString()
        }
      ]
    ]);
  }
};

export const createExamAnswer = async (userId, examId, answerMap) => {
  const answerMapKeys = Object.keys(answerMap);
  answerMapKeys.forEach(qNr => {
    addExamAnswer(userId, examId, qNr, answerMap[qNr]);
  });
};

export const finishExam = async (userId, examId) => {
  const exam = await getExamById(userId, examId);
  if (exam && exam.finish) {
    // exam already finished => don't update endTime again!
    return;
  }

  await alasql('UPDATE exam SET finish = ?, endTime = ? WHERE examId = ?', [true, +new Date(), examId]);
};

export const lastExam = async userId => {
  const exams = await alasql('SELECT * FROM exam ORDER BY startTime DESC LIMIT 1');

  return exams[0] ? exams[0] : null;
};

export const getLastExams = async (userId, categoryForQuery) => {
  const exams = await alasql('SELECT * FROM exam WHERE category = ? ORDER BY startTime DESC LIMIT 4', [
    categoryForQuery
  ]);

  return exams.map(item => ({
    data: item,
    id: item.examId
  }));
};

export const getAllExams = async (userId, categoryForQuery) => {
  const exams = await alasql('SELECT * FROM exam WHERE category = ? ORDER BY startTime DESC', [categoryForQuery]);

  const info = exams.sort((a, b) => a.questionCount / a.trueQuestionCount - b.questionCount / b.trueQuestionCount);

  return info[0] ? info[0] : null;
};
