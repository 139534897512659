import React from 'react';
import { Col, Row, Container, Navbar, Nav } from 'react-bootstrap';
import { LinkWrapper as Link } from '../../ui-components/LinkWrapper/LinkWrapper';
import logo from '../../img/acs70.png';
import { lngList } from '../../locales/locale';
import Translate from '../Translate/Translate';
import { IHeaderProps } from './Header.types';
import styles from './Header.module.css';

const Header: React.FC<IHeaderProps> = ({ routeProps }: IHeaderProps) => {
  return (
    <div className={styles.header}>
      <div className={styles.headerTop}>
        <Container>
          <Row>
            <Col>
              <div className={styles.headerTopContent}>
                <ul className={styles.headerTopLinkList}>
                  <li>
                    <a
                      className={styles.headerTopLinkItem}
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.facebook.com/ctmalder/"
                    >
                      <i className="icon-facebook" />
                    </a>
                  </li>
                  <li>
                    <a
                      className={styles.headerTopLinkItem}
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://www.instagram.com/ctm_alder_ag/?hl=de"
                    >
                      <i className="icon-instagram" />
                    </a>
                  </li>
                </ul>
                <div className={styles.langBtnGroup}>
                  {Object.keys(lngList).map((lng: string) => {
                    return (
                      <Translate key={lng} lng={lngList[lng].shortName} routeProps={routeProps}>
                        {lngList[lng].fullName}
                      </Translate>
                    );
                  })}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Navbar expand="lg" collapseOnSelect>
        <Container>
          <Navbar.Brand>
            <Nav>
              <Nav.Link as={Link} to="/" href="#">
                <img src={logo} width={100} alt="logo" />
              </Nav.Link>
            </Nav>
          </Navbar.Brand>
        </Container>
      </Navbar>
    </div>
  );
};

export default Header;
