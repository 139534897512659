import React, { useState, useEffect, useCallback, useRef } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'react-bootstrap';
import useReactRouter from 'use-react-router';
import { RouteComponentProps } from 'react-router';
import { ITheoryBookProps } from './TheoryBook.types';
import {
  getTheoryContentImgAnchorIndex,
  getTheoryImage,
  scrollToAnchor,
  splitTextByLines,
  splitTextContentWithImgToChunks
} from '../../utils/utils';
import ReadSpeaker from '../ReadSpeaker/ReadSpeaker';
import { THEORY_POSTFIX } from '../../constants/rsPostfix';
import { P3 } from '../../ui-components/P3/P3';
import { IPages } from '../../reducer/theory.types';
import Image from '../../ui-components/Image/Image';
import { LinkWrapper } from '../../ui-components/LinkWrapper/LinkWrapper';
import { THEORY_PAGE_ANCHOR } from '../../constants/anchors';
import styles from './TheoryBook.module.css';
import useDeviceSizes from '../../hooks/useDeviceSizes';

export const TheoryBook: React.FC<ITheoryBookProps> = ({
  page,
  inModal,
  pathName = 'topics',
  pageChapters,
  groupPages,
  currentLanguage
}: ITheoryBookProps) => {
  const isGlossaryMode = pathName === 'glossary';
  const isTopicsMode = pathName === 'topics';
  const { t } = useTranslation();
  const { width } = useDeviceSizes();
  const isMobileMode = width <= 991;
  const [showModal, setShowModal] = useState(false);
  const [index, setIndex] = useState(0);
  const pages: string[] = Object.keys(groupPages);
  const { history, location }: RouteComponentProps = useReactRouter();
  const prevLocationKeyRef = useRef<string | undefined>(location.key);
  const currentPage: IPages[] = groupPages[pages[index]];
  const title = pageChapters[pages[index]];

  const ref = useRef<HTMLDivElement>(document.createElement('div'));

  const scrollReset = () => {
    scrollToAnchor(THEORY_PAGE_ANCHOR);
    ref.current.scrollTop = 0;
  };

  const handleChangePathname = useCallback(
    (pageItem: string) => {
      history.push(`/${currentLanguage}/theory/${pathName}/${pageItem}`);
      scrollReset();
    },
    [history, currentLanguage, pathName]
  );

  const handleNextPage = useCallback(() => {
    if (pages[index + 1]) {
      if (inModal) {
        setIndex(index + 1);
      } else {
        handleChangePathname(pages[index + 1]);
      }
      isMobileMode && scrollToAnchor(THEORY_PAGE_ANCHOR);
    }
  }, [handleChangePathname, index, pages, inModal, isMobileMode]);

  const handlePrevPage = useCallback(() => {
    if (pages[index - 1]) {
      if (inModal) {
        setIndex(index - 1);
      } else {
        handleChangePathname(pages[index - 1]);
      }
      isMobileMode && scrollToAnchor(THEORY_PAGE_ANCHOR);
    }
  }, [handleChangePathname, index, pages, inModal, isMobileMode]);

  useEffect(() => {
    setIndex(Object.keys(groupPages).indexOf(String(page)));
  }, [page, groupPages]);

  useEffect(() => {
    if (prevLocationKeyRef.current !== location.key) {
      setShowModal(true);
    }
  }, [prevLocationKeyRef, location.key]);

  useEffect(() => {
    prevLocationKeyRef.current = location.key;
    scrollReset();
  }, [location]);

  const handleOnHide = useCallback(() => {
    setShowModal(false);
  }, [setShowModal]);

  const composeTheoryTitle = title ? <p className={`glossary-title text-to-read${THEORY_POSTFIX}`}>{title}</p> : null;

  const composedContent = currentPage
    ? currentPage.map((pageFromGroup: IPages) => {
        const [subTitle, ...texts]: string[] = splitTextByLines(pageFromGroup.text);
        const filterImgList = pageFromGroup.img.filter(Boolean);
        const filteredParagraphs = texts.filter(Boolean);

        return (
          <div className={styles.glossaryImgList} key={`${page}${currentLanguage}${pageFromGroup.img}`}>
            <p className={cx('glossary-subtitle', `text-to-read${THEORY_POSTFIX}`)}>{subTitle}</p>
            {filterImgList.length > 0 &&
              filterImgList
                .filter((_, item) => !filteredParagraphs.some(text => text.includes(`[IMAGE${item + 1}]`)))
                .map(imgKey => (
                  <Image
                    key={imgKey}
                    className={styles.imgContent}
                    placeholderClassName={styles.imgPlaceholderContent}
                    src={getTheoryImage(imgKey)}
                  />
                ))}
            {filteredParagraphs.map(textItem => {
              if (!textItem.includes('[IMAGE')) {
                return (
                  <P3 key={textItem} className={cx('glossary-text', `text-to-read${THEORY_POSTFIX}`)}>
                    {textItem}
                  </P3>
                );
              }

              return splitTextContentWithImgToChunks(textItem).map(subItem => {
                const imgKey = filterImgList[Number(getTheoryContentImgAnchorIndex(subItem)) - 1];
                return imgKey ? (
                  <Image
                    key={imgKey}
                    className={styles.imgContent}
                    placeholderClassName={styles.imgPlaceholderContent}
                    src={getTheoryImage(imgKey)}
                  />
                ) : (
                  <span key={subItem} className={`text-to-read${THEORY_POSTFIX}`}>
                    {subItem}
                  </span>
                );
              });
            })}
          </div>
        );
      })
    : null;

  const controls = (
    <div className={styles.glossaryFooter}>
      <Button
        className={styles.btnPrev}
        variant="secondary"
        onClick={handlePrevPage}
        disabled={!groupPages[pages[index - 1]]}
      >
        <i className="icon-arrow" />
      </Button>
      {isTopicsMode && (
        <LinkWrapper to="/theory" className={styles.topicLink}>
          {t('theoryPage.pageView.linkBack')}
        </LinkWrapper>
      )}
      <Button
        className={styles.btnNext}
        variant="secondary"
        onClick={handleNextPage}
        disabled={!groupPages[pages[index + 1]]}
      >
        <i className="icon-arrow" />
      </Button>
    </div>
  );

  return (
    <>
      {isGlossaryMode && isMobileMode && (
        <Modal
          show={showModal}
          size="sm"
          onHide={handleOnHide}
          className={styles.modal}
          backdropClassName={styles.backdrop}
          scrollable
        >
          <Modal.Header closeButton>{composeTheoryTitle}</Modal.Header>
          <Modal.Body>
            {composedContent}
            {controls}
          </Modal.Body>
        </Modal>
      )}
      <div ref={ref} className={cx(styles.glossaryScrollBox, { [styles.withModal]: isGlossaryMode })}>
        <div className={styles.glossaryHeader}>
          {' '}
          {composeTheoryTitle}
          {!isGlossaryMode && (
            <ReadSpeaker
              className={styles.questionBtnListItem}
              lng={currentLanguage}
              rsPostfix={THEORY_POSTFIX}
              stopParam={index}
            />
          )}
        </div>

        {composedContent}
        {controls}
      </div>
    </>
  );
};
