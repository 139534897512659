import React from 'react';
import { Modal } from 'react-bootstrap';
import { IMultimediaModalProps } from './MultimediaModal.types';
import styles from './MultimediaModal.module.css';

const MultimediaModal: React.FC<IMultimediaModalProps> = ({ onHide, show, src, alt, video }: IMultimediaModalProps) => {
  return (
    <Modal
      className={styles.multiModal}
      show={show}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      onHide={onHide}
    >
      <Modal.Header closeButton />
      <Modal.Body>
        {video ? (
          <video className={styles.videoContent} src={src} muted playsInline controls crossOrigin="anonymous">
            <source src={src} type="video" />
            <track kind="captions" />
          </video>
        ) : (
          <img className={styles.zoomImg} src={src} alt={alt} />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default MultimediaModal;
