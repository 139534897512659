import React, { useEffect } from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useReactRouter from 'use-react-router';
import appHistory from './history';
import HeaderContainer from '../containers/HeaderContainer';
import { PrivateRoute } from './PrivateRoute';
import { routes } from './routes';
import { PENDING, UNCALLED } from '../constants/store.constants';
import { IRootState } from '../reducer';
import { DefaultRoute } from './DefaultRoute';
import Footer from '../components/Footer/Footer';
import { CLEAR_EXAM, GET_LAST_EXAM_REQUEST } from '../actions/exam.action';
import { selectLanguage } from '../reducer/user.selectors';
import ScrollToTopButton from '../components/ScrollToTopButton/ScrollToTopButton';
import { LoginState } from '../reducer/login.types';
import { selectLogin } from '../reducer/login.selectors';

export const AppRouter: React.FC = () => {
  const { isLogged }: LoginState = useSelector(selectLogin);
  const showNotAvailable: boolean = useSelector(({ user }: IRootState) => user.showNotAvailable);
  const lastExamRequestStatus = useSelector(({ exam }: IRootState) => exam.lastExamRequest.status);
  const lastExam = useSelector(({ exam }: IRootState) => exam.lastExam);
  const dispatch = useDispatch();
  const { history } = useReactRouter();
  const currentLanguage = useSelector(selectLanguage);
  const loaderState = false;

  useEffect(() => {
    if (lastExamRequestStatus === UNCALLED && !loaderState) {
      dispatch(GET_LAST_EXAM_REQUEST());
    }
  }, [dispatch, lastExamRequestStatus, loaderState, isLogged]);

  useEffect(() => {
    if (lastExam && !lastExam.finish && !lastExam.endTime && !loaderState) {
      const notFinished = lastExam.examStartTime + lastExam.duration * 60 * 1000 > Date.now();
      const newPath = `/${currentLanguage}/exam/${lastExam.uid}`;
      if (notFinished && !history.location.pathname.includes(`/exam/${lastExam.uid}`)) {
        dispatch(CLEAR_EXAM());
        history.push(newPath);
      }
    }
  }, [lastExam, history, currentLanguage, loaderState, dispatch]);

  return (
    <>
      <ScrollToTopButton />

      <Router history={appHistory}>
        {!showNotAvailable && <HeaderContainer />}
        <main>
          <Switch>
            {routes.map(route => (
              <Route
                key={route.path}
                exact={route.exact}
                path={`/:locale(en|de|it|fr)?${route.path}`}
                render={routeProps => (
                  <PrivateRoute
                    showNotAvailable={showNotAvailable}
                    routeProps={routeProps}
                    demoPathName={route.demoPathName}
                    renderAfterLoad={route.renderAfterLoad}
                    withLoader={route.withLoader}
                    component={route.component}
                    onlyForDemo={route.onlyForDemo}
                    loaderState={
                      loaderState && (lastExamRequestStatus !== PENDING || lastExamRequestStatus !== UNCALLED)
                    }
                  />
                )}
              />
            ))}
            <Route path="*" component={DefaultRoute} />
          </Switch>
        </main>
        {!showNotAvailable && (
          <>
            <Footer />
          </>
        )}
      </Router>
    </>
  );
};
