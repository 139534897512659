import React, { Suspense } from 'react';

import { AppRouter } from './router/AppRouter';

const App: React.FC = () => {
  return (
    <Suspense fallback="Loading">
      <AppRouter />
    </Suspense>
  );
};

export default App;
