import moment from 'moment';
import { defaultLanguage } from '../locales/locale';

const LOCALE_MAP = {
  en: 'en-gb',
  it: 'it',
  fr: 'fr',
  de: 'de'
};

export const setMomentLocale = (locale?: string) => {
  if (!locale || !LOCALE_MAP[locale]) {
    moment.locale(defaultLanguage);
  } else {
    moment.locale(LOCALE_MAP[locale]);
  }
};
