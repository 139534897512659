import React from 'react';
import { useTranslation } from 'react-i18next';

const NotAvailablePage: React.FC = () => {
  const { t } = useTranslation();

  return <div>{t('notAvailable')}</div>;
};

export default NotAvailablePage;
