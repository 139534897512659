import { put, takeEvery, all } from 'redux-saga/effects';
import {
  GET_USER_EXAM_RESULTS_REQUEST,
  GET_USER_EXAM_RESULTS_SUCCESS,
  GET_USER_EXAM_RESULTS_FAILURE
} from '../actions/user.action';
import { getLastExams, getAllExams } from './exam.api.fb';
import { getFBCategoryName } from '../utils/utils';

function* getUserExamInfo({ payload }) {
  try {
    const { currentCategory } = payload;
    const categoryForQuery = getFBCategoryName(currentCategory);
    const userId = null;

    const lastExamsInfo = yield getLastExams(userId, categoryForQuery);

    const sortedbyCorrectAnswers = yield getAllExams(userId, categoryForQuery);

    yield put(
      GET_USER_EXAM_RESULTS_SUCCESS({
        lastExams: lastExamsInfo,
        bestResult: sortedbyCorrectAnswers
      })
    );
  } catch (e) {
    yield put(GET_USER_EXAM_RESULTS_FAILURE(e.message));
  }
}

function* userSaga() {
  yield all([takeEvery(GET_USER_EXAM_RESULTS_REQUEST, getUserExamInfo)]);
}

export default userSaga;
