import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import TheoryByTopicContainer from '../../containers/TheoryByTopicContainer';
import InstructorContainer from '../../containers/InstructorContainer/InstructorContainer';
import image from '../../img/EcoDrive.jpg';
import { ITheoryPageProps } from './EcodrivePage.types';
import styles from './EcodrivePage.module.css';
import { RulesGroupContent } from '../../ui-components/RulesGroupContent/RulesGroupContent';
import { RulesGroupItem } from '../../ui-components/RulesGroupItem/RulesGroupItem';
import PageDescription from '../../ui-components/PageDescription/PageDescription';

const EcodrivePage: React.FC<ITheoryPageProps> = ({ match }: ITheoryPageProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.theoryPage}>
      <Container>
        <Row>
          <Col>
            <div className={styles.theoryPageTopContent}>
              <RulesGroupContent>
                <div className={styles.imageBox}>
                  <InstructorContainer src={image} hideForMobile />
                </div>
                <RulesGroupItem>
                  <PageDescription title={t('ecodrive.title')} description={t('ecodrive.descr')} />
                </RulesGroupItem>
              </RulesGroupContent>
            </div>
          </Col>
        </Row>
      </Container>
      <p>Markus: Inhalte Patrick</p>
      <TheoryByTopicContainer page={match.params.page} />
    </div>
  );
};

export default EcodrivePage;
