import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import styles from './QuestionBar.module.css';
import { IQuestionBarProps } from './QuestionBar.types';
import LngDropDown from '../LngDropDown/LngDropDown';
import QuestionChoose from '../QuestionChoose/QuestionChoose';
import TheoryModal from '../TheoryModal/TheoryModal';
import ReadSpeaker from '../ReadSpeaker/ReadSpeaker';

const QuestionBar: React.FC<IQuestionBarProps> = ({
  isLogged,
  onLngChange,
  onLngToggleClick,
  onToggleChangeLng,
  languageToggleState,
  selectedLang,
  onQuestionChange,
  lng,
  topic,
  defaultRoute,
  questionId,
  userLanguage,
  questionLngList,
  finishedExam,
  theoryId,
  isExam,
  isFavorite,
  addToFavorite,
  questionList
}: IQuestionBarProps) => {
  const [showTheory, setShowTheory] = useState(false);

  const handleToFavorite = useCallback(() => {
    if (addToFavorite && questionId) {
      addToFavorite(questionList[questionId - 1].id);
    }
  }, [addToFavorite, questionId, questionList]);

  const handleCloseTheoryModal = useCallback(() => {
    setShowTheory(false);
  }, [setShowTheory]);

  const handleOpenTheoryModal = useCallback(() => {
    setShowTheory(true);
  }, [setShowTheory]);

  return (
    <div className={styles.questionBtnList}>
      {isExam && addToFavorite && (
        <button type="button" className={styles.questionBtnListItem} onClick={handleToFavorite}>
          <i
            className={classNames({
              'icon-star-line': !isFavorite,
              'icon-star-fill': isFavorite
            })}
          />
        </button>
      )}
      {isLogged && theoryId && (
        <>
          <button type="button" className={styles.questionBtnListItem} onClick={handleOpenTheoryModal}>
            <i className="icon-theory" />
          </button>
          <TheoryModal show={showTheory} onHide={handleCloseTheoryModal} theoryId={theoryId} />
        </>
      )}

      <ReadSpeaker className={styles.questionBtnListItem} lng={lng} />

      <QuestionChoose
        onQuestionChange={onQuestionChange}
        isExam={isExam}
        finishedExam={finishedExam}
        topic={topic}
        defaultRoute={defaultRoute}
        questionList={questionList}
        iconCls={styles.questionBtnListItem}
      />

      <LngDropDown
        onLngToggleClick={onLngToggleClick}
        onToggleChangeLng={onToggleChangeLng}
        userLanguage={userLanguage}
        lng={lng}
        onLngChange={onLngChange}
        languageToggleState={languageToggleState}
        selectedLang={selectedLang}
        iconCls={styles.questionBtnListItem}
        questionLngList={questionLngList}
      />
    </div>
  );
};

export default QuestionBar;
