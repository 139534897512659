import React from 'react';
import { Jumbotron } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { IContactCardProps } from './ContactCard.types';
import styles from './ContactCard.module.css';

export const ContactCard: React.FC<IContactCardProps> = ({ title, text1, text2 }: IContactCardProps) => {
  const { t } = useTranslation();

  return (
    <Jumbotron className={styles.contactItem}>
      <div>
        <div className={styles.contactTitle}>{t(title)}</div>
        <p className={styles.contactText}>{t(text1)}</p>
      </div>
      <div className={styles.contactFooter}>{t(text2)}</div>
    </Jumbotron>
  );
};
