import { combineReducers } from 'redux';
import { connectRouter, RouterState } from 'connected-react-router';
import { History } from 'history';
import { TopicState } from './topic.types';
import { IUserState } from './user.types';
import { LoginState } from './login.types';
import { SchoolState } from './school.types';
import { IQuestionState } from './question.types';
import { IAnswerState } from './answer.types';
import { IExamState } from './exam.types';
import { INavigationMenuState } from './navigationMenu.types';
import { ITheoryState } from './theory.types';
import topic from './topic';
import question from './question';
import category from './category';
import login from './login';
import user from './user';
import school from './school';
import answer from './answer';
import exam from './exam';
import navigationMenu from './navigationMenu';
import theory from './theory';
import { ICategoryState } from './category.types';

export interface IRootState {
  readonly topic: TopicState;
  readonly user: IUserState;
  readonly category: ICategoryState;
  readonly answer: IAnswerState;
  readonly question: IQuestionState;
  readonly login: LoginState;
  readonly exam: IExamState;
  readonly school: SchoolState;
  readonly router: RouterState;
  readonly navigationMenu: INavigationMenuState;
  readonly theory: ITheoryState;
}

const rootReducer = (history: History) =>
  combineReducers<IRootState>({
    router: connectRouter(history),
    question,
    category,
    topic,
    answer,
    login,
    user,
    exam,
    school,
    navigationMenu,
    theory
  });

export default rootReducer;
